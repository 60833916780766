import { LOCAL_STORAGE_ITEMS, MINUTES_MANAGER__BREAK_ALL_LANGUAGES, MINUTES_MANAGER__END_ALL_LANGUAGES, MINUTES_MANAGER__START_ALL_LANGUAGES } from "./constants";

export const noOp = () => undefined;

export const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = (string) => typeof string === "string" || string instanceof String;

export const formatTime = (timeline) => {
  const date = new Date(timeline * 1000);

  const hours = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  const minutes = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`;
  const seconds = date.getSeconds() > 10 ? date.getSeconds() : `0${date.getSeconds()}`;

  return `${hours}:${minutes}:${seconds}`;
};

export const formatDateTime = (timestamp) => {
  const formattedDateTime = new Date(timestamp * 1000)
    .toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(",", " -");
  return formattedDateTime;
};
export const formatTwoDigits = (number) => {
  let numStr = number.toString();
  if (number < 10) {
    numStr = "0" + numStr;
  }
  return numStr;
};
export const formatThreeDigits = (number) => {
  let numStr = number.toString();
  if (number < 10) {
    numStr = "00" + numStr;
  }
  return Number(numStr).toFixed(0);
};
export const getObjectTime = (msTime) => {
  const milliseconds = msTime % 1000;
  const totalSeconds = Math.floor(msTime / 1000);
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};
export const language =
  localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE) || navigator.language.split(/[-_]/)[0];

export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(email);
};

export const getUniquesObjectsByProperty = (property, array1, array2) => {
  let idsArray2 = array2.map((obj) => obj[property]);

  return array1.filter((obj) => !idsArray2.includes(obj[property]));
};
export const convertTimeRounded = (time) => {
  return time < 999 ? Math.round(time * 1000) : Math.round(time);
};

export const sortedParticipantsByName = (participants) => {
  participants.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  return participants;
};

export const getLocalizedDescription = (point, locale) => {
  return point.item_description_translations?.[locale] || point.item_description;
};

export const isItemStartItem = (item) => {
  let itemDescriptions = Object.values(item.item_description_translations || {});
  itemDescriptions.push(item.item_description);
  return MINUTES_MANAGER__START_ALL_LANGUAGES.some((startText) => itemDescriptions.includes(startText));
}

export const isItemEndItem = (item) => {
  let itemDescriptions = Object.values(item.item_description_translations || {});
  itemDescriptions.push(item.item_description);
  return MINUTES_MANAGER__END_ALL_LANGUAGES.some((endText) => itemDescriptions.includes(endText));
}

export const isItemBreakItem = (item) => {
  let itemDescriptions = Object.values(item.item_description_translations || {});
  itemDescriptions.push(item.item_description);
  return MINUTES_MANAGER__BREAK_ALL_LANGUAGES.some((breakText) => itemDescriptions.includes(breakText));
}
